import type {
  SocialLinkComponent,
  SocialLinkComponentInput,
} from '@cardo/types';

import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoGithub,
  IoLogoYoutube,
  IoLogoTiktok,
} from 'react-icons/io5';
import type { IconType } from 'react-icons';

type IconMap = {
  [key: string]: IconType;
};

const iconMap = {
  facebook: IoLogoFacebook,
  instagram: IoLogoInstagram,
  linkedin: IoLogoLinkedin,
  twitter: IoLogoTwitter,
  github: IoLogoGithub,
  youtube: IoLogoYoutube,
  tiktok: IoLogoTiktok,
} as IconMap;

export function SocialLink({
  url,
  icon,
}: SocialLinkComponent | SocialLinkComponentInput) {
  const IconComponent = iconMap[icon?.toLowerCase()];

  if (!IconComponent) {
    return null;
  }

  return (
    <div className="">
      <a href={url} target="_blank" rel="noreferrer">
        <IconComponent className="text-3xl text-theme-blue-darkest hover:text-theme-purple" />
      </a>
    </div>
  );
}
