import { Link } from '@remix-run/react';
import { IoChevronDownSharp } from 'react-icons/io5';

type MobileNavItemDropdownProps = {
  to: string;
  title: string;
  children?: React.ReactNode;
  close: () => void;
};

export default function MobileNavItemDropdown({
  to,
  title,
  children,
  close,
}: MobileNavItemDropdownProps) {
  return (
    <div className="relative flex flex-col py-3">
      <Link
        to={to}
        className="no-underline font-semibold text-xl flex items-center text-theme-blue-darkest"
        onMouseDown={close}
      >
        <span>{title}</span>
        {children && <IoChevronDownSharp className="ml-2 mt-0.5" />}
      </Link>

      {children && <div className="flex flex-col pt-2">{children}</div>}
    </div>
  );
}
