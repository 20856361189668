import { Form } from '@remix-run/react';
import classNames from 'classnames';
import { IoSearch } from 'react-icons/io5';

type CoreSearchBoxProps = {
  className?: string;
};

export default function CoreSearchBox({ className }: CoreSearchBoxProps) {
  return (
    <div className={classNames('relative flex w-full sm:w-auto', className)}>
      <Form action="/search" className="w-full sm:w-auto" reloadDocument>
        <div className="absolute left-0 flex h-full items-center">
          <IoSearch className="text-2xl" />
        </div>
        <input
          type="search"
          name="q"
          className="placeholder:text-theme-blue-darkest focus:border-b-theme-blue-darkest w-40 border-0 border-b border-b-white px-3 pl-8 text-xl outline-none ring-0 transition-all duration-300 ease-in-out focus:w-80 focus:outline-none focus:ring-0 focus:placeholder:text-slate-400"
          placeholder="Search..."
        />
      </Form>
    </div>
  );
}
