type NavGroupProps = {
  title: string;
  children: React.ReactNode;
};

export default function NavGroup({ title, children }: NavGroupProps) {
  return (
    <div className="flex flex-col space-y-2">
      <div className="text-theme-blue-darkest text-lg border-b-2 border-b-theme-blue-darkest">
        {title}
      </div>
      <div className="flex flex-col space-y-1.5">{children}</div>
    </div>
  );
}
