import { Link } from '@remix-run/react';

type MobileNavItemProps = {
  to: string;
  title: string;
  close: () => void;
};

export default function MobileNavItem({
  to,
  title,
  close,
}: MobileNavItemProps) {
  return (
    <Link
      to={to}
      className="py-0.5 px-5 w-full no-underline font-semibold text-lg text-theme-blue-darkest"
      onMouseDown={close}
    >
      {title}
    </Link>
  );
}
