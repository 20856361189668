import { Link } from '@remix-run/react';
import NavGroup from './NavGroup';

export default function FooterNav() {
  const authenticatedAppUrl =
    typeof document !== 'undefined'
      ? window.ENV.AUTHENTICATED_APP_URL
      : process.env.AUTHENTICATED_APP_URL;

  return (
    <div className="flex space-x-6">
      <NavGroup title="Creators">
        <Link to={authenticatedAppUrl || ''}>Login</Link>
        <Link to="/affiliates">Cardonomics Affiliate Program</Link>
      </NavGroup>
      <NavGroup title="Site">
        <Link to="/terms-of-service">Terms of Service</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/cookie-policy">Cookie Policy</Link>
      </NavGroup>
    </div>
  );
}
