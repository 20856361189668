import { Link } from '@remix-run/react';

type NavItemProps = {
  to: string;
  title: string;
};

export default function NavItem({ to, title }: NavItemProps) {
  return (
    <Link
      to={to}
      className="text-theme-blue-darkest py-2 px-6 w-full no-underline font-semibold hover:bg-gray-100 last:rounded-b-lg"
      prefetch="intent"
    >
      {title}
    </Link>
  );
}
