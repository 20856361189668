import { Link } from '@remix-run/react';
import { IoChevronDownSharp } from 'react-icons/io5';

type NavItemDropdownProps = {
  to: string;
  title: string;
  children?: React.ReactNode;
};

export default function NavItemDropdown({
  to,
  title,
  children,
}: NavItemDropdownProps) {
  return (
    <div className="relative flex flex-col w-fit group z-40">
      <Link
        to={to}
        className="cursor-pointer peer no-underline text-theme-blue-darkest text-xl"
        prefetch="intent"
      >
        <div className="group peer flex items-center py-2 font-semibold">
          <span className="whitespace-nowrap">{title}</span>
          {children && (
            <IoChevronDownSharp className="ml-2 text-2xl group-hover:rotate-180 transition-transform" />
          )}
        </div>
      </Link>
      {children && (
        <div className="absolute top-full h-0 p-0 overflow-hidden peer-hover:h-fit hover:h-fit w-full flex hover:pb-1 peer-hover:pb-1 hover:pt-7 peer-hover:pt-7 -translate-x-[0.31rem] flex-co transition-[height] delay-500">
          <div className="flex flex-col bg-white shadow-md w-full rounded-b-lg overflow-hidden">
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
